/*
* 各类通用参数
* 每个数值内各个KEY 的作用
* sort_num : 用来数组排序的,并非参数相关的数值
* id/value : 对应后台数据库的值
* name     : 前端用来显示的值
* 其他KEY可自定义
*
*/
class ParamClass extends Array {
	constructor(value){
		super()
		value.sort((a,b) => {
			if(isNaN(a.sort_num) || isNaN(b.sort_num)){
				if(!isNaN(a.sort_num) && !isNaN(b.sort_num)){
					return a.id - b.id
				}
			} else {
				return a.sort_num - b.sort_num
			}
			return 0
		})
		this.__protoArray__ = value
		for(var i=0; i< value.length; i++){
			this[i] = value[i];
		}
	}
	// 根据ID 查询name
	getName(id, defaultValue){
		for(var i=0; i< this.length; i++){
			if(this[i].id == id){
				return this[i].name
			}
		}
		return defaultValue
	}
	// 根据ID 查询完整数据
	getOption(id){
		for(var i=0; i< this.length; i++){
			if(this[i].id == id){
				return this[i]
			}
		}
		return {}
	}
	map(mapFunc){
		return new ParamClass(this.__protoArray__.map(mapFunc))
	}
	build(){
		return new ParamClass(this.__protoArray__)
	}
	filter(mapFunc){
		return new ParamClass(this.__protoArray__.filter(mapFunc))
	}
}
let Param = {
	alarmType: new ParamClass([
		{
			id      : 1,
			sort_num: 1,
			name    : "alarm_type_1",
		},{
			id      : 2,
			sort_num: 2,
			name    : "alarm_type_2",
		},{
			id      : 3,
			sort_num: 3,
			name    : "alarm_type_3",
		},{
			id      : 4,
			sort_num: 4,
			name    : "alarm_type_4",
		},{
			id      : 5,
			sort_num: 5,
			name    : "alarm_type_5",
		},{
			id      : 6,
			sort_num: 6,
			name    : "alarm_type_6",
		},{
			id      : 7,
			sort_num: 7,
			name    : "alarm_type_7",
		}
	]),
	tempParamsList: new ParamClass([
		{
			id      : 1,
			sort_num: 1,
			name    : "system",
		},{
			id      : 2,
			sort_num: 2,
			name    : "total_count",
		},{
			id      : 3,
			sort_num: 3,
			name    : "alarm",
		}
	]),
	pubState: new ParamClass([
		{
			id      : 1,
			sort_num: 1,
			name    : "release",
		},{
			id      : 2,
			sort_num: 2,
			name    : "delist",
		}
	]),
	orderTypeList : new ParamClass([
		{
			id      : 1,
			sort_num: 1,
			name    : "rest_purch",
		},{
			id      : 2,
			sort_num: 2,
			name    : "normal_purch",
		},{
			id      : 3,
			sort_num: 3,
			name    : "get_free",
		},{
			id      : 4,
			sort_num: 4,
			name    : "basic_pack",
		}
	]),
  srvList: new ParamClass([
    {
      name: "账号服务",
      id  : "kdumh",
      sort_num:1,
    },{
      name: "OTA服务",
      id  : "kdota",
      sort_num:2,
    },{
      name: "设备服务",
      id  : "kdiot",
      sort_num:3,
    },{
      name: "商场服务",
      id  : "kdmall",
      sort_num:4,
    },{
      name: "云存服务",
      id  : "kdoss",
      sort_num:5,
    },{
      name: "推送服务",
      id  : "kdpush",
      sort_num:6,
    },{
      name: "服务治理",
      id  : "kdreg",
      sort_num:7,
    },{
      name: "后台管理",
      id  : "kduser",
      sort_num:8,
    },{
      name: "网关服务",
      id  : "kdgateway",
      sort_num:9,
    },{
      name: "系统采集",
      id  : "kdmon",
      sort_num:10,
    },{
      name: "日志服务",
      id  : "kdkls",
      sort_num:11,
    },{
      name: "生产服务",
      id  : "kdfac",
      sort_num:12,
    },
  ]),
	noticeTempType : new ParamClass([ // 通知模板类型
		{
			id      : 1,
			sort_num: 1,
			name    : "subscr_succ_notice",
		},{
			id      : 2,
			sort_num: 2,
			name    : "subscr_fail_notice",
		},{
			id      : 3,
			sort_num: 3,
			name    : "subscr_renew_fail_notice",
		},{
			id      : 4,
			sort_num: 4,
			name    : "subscr_notice_renew_notice",
		},{
			id      : 5,
			sort_num: 5,
			name    : "subscr_renew_succ_notice",
		},{
			id      : 6,
			sort_num: 6,
			name    : "buy_succ_notice",
		},{
			id      : 7,
			sort_num: 7,
			name    : "cancle_subscr_notice",
		},{
			id      : 8,
			sort_num: 8,
			name    : "exp_noti",
		},
	]), // 通知模板类型
	serverType: new ParamClass([ // 服务器类型
		{
		id: 2,
		sort_num: 2,
		name:"AUTH"
		},{
		id: 4,
		sort_num: 4,
		name:"GW"
		},{
		id: 5,
		sort_num: 5,
		name:"NAT"
		},{
		id: 6,
		sort_num: 6,
		name:"HKSP"
		},{
		id: 7,
		sort_num: 7,
		name:"RTSP"
		},{
		id: 8,
		sort_num: 8,
		name:"HTTP"
		},{
		id: 9,
		sort_num: 9,
		name:"ALARM"
		},{
		id: 10,
		sort_num: 10,
		name:"VOD"
		},{
		id: 11,
		sort_num: 11,
		name:"SP"
		},{
		id: 12,
		sort_num: 12,
		name:"UST"
		},{
		id: 14,
		sort_num: 14,
		name:"UTD"
		},{
		id: 15,
		sort_num: 15,
		name:"CSI"
		},{
		id: 16,
		sort_num: 16,
		name:"CSF"
		},{
		id: 17,
		sort_num: 17,
		name:"UMH"
		},{
		id: 18,
		sort_num: 18,
		name:"DS"
		},{
		id: 19,
		sort_num: 19,
		name:"WOL",
		},{
		id :20,
		sort_num: 20,
		name : "umgreg",
		i18n: true,
		},{
		id :21,
		sort_num: 21,
		name : "umguser",
		i18n: true,
		},{
		id :22,
		sort_num: 22,
		name : "umgopen",
		i18n: true,
		},{
		id :23,
		sort_num: 23,
		name : "umgmall",
		i18n: true,
		},{
		id :24,
		sort_num: 24,
		name : "umgpay",
		i18n: true,
		},{
		id :25,
		sort_num: 25,
		name : "umposs",
		i18n: true,
		},{
		id :26,
		sort_num: 26,
		name : "umiot",
		i18n: true,
		},{
		id : 27,
		sort_num: 27,
		name : "umgpush",
		i18n: true,
		},{
		id : 28,
		sort_num: 28,
		name : "ota_server",
		i18n: true,
		},{
		id : 29,
		sort_num: 29,
		name : "umgda",
		i18n: true,
		},{
		id : 30,
		sort_num: 30,
		name : "GUMH"
		},{
		id : 34,
		sort_num: 34,
		name : "KLS"
		}
	]), //  服务器类型
	serverStatus:new ParamClass([ // 服务器状态
		{
			id: 1,
			sort_num: 1,
			name: "inline"
		},{
			id: 2,
			sort_num: 2,
			name: "offline"
		// },{
		// 	id: 3,
		// 	sort_num: 3,
		// 	name: "unauth"
		// },{
		// 	id: 4,
		// 	sort_num: 4,
		// 	name: "outline"
		}
	]), // 服务器状态
	deviceType:new ParamClass([ // 设备类型
		{
			id: 1,
			sort_num: 1,
			name: "home_camera"
		},{
			id: 2,
			sort_num: 2,
			name: "cellular_equipemnt"
		},{
			id: 3,
			sort_num: 3,
			name: "hard_video"
		},{
			id: 4,
			sort_num: 4,
			name: "out_video"
		},{
			id: 5,
			sort_num: 5,
			name: "low_power"
		}
	]), // 设备类型
	netType: new ParamClass([ // 网络类型
		{
			id: 1,
			sort_num: 1,
			name: "unicom",
		},
		{
			id: 2,
			sort_num: 2,
			name: "cmc",
		},
		{
			id: 3,
			sort_num: 3,
			name: "telecom",
		},
		{
			id: 4,
			sort_num: 4,
			name: "sannetcom",
		},
		{
			id: 5,
			sort_num: 5,
			name: "overseas",
		},
	]), // 网络类型
	iccType: new ParamClass([ // ICC类型
		{
			id: 1,
			sort_num: 1,
			name: "quectel",
		},
		{
			id: 2,
			sort_num: 2,
			name: "dxy",
		},{
			id: 3,
			sort_num: 3,
			name: "eiotclub",
		},{
			id: 4,
			sort_num: 4,
			name: "easylink",
		}
	]), // ICC类型
	videoGoodType: new ParamClass([ // 录像类型
		{
			id: 1,
			sort_num: 1,
			name: "good_type_1",
		}, {
			id: 2,
			sort_num: 2,
			name: "good_type_2",
		},
	]), // 录像类型
	lifecycle: new ParamClass([ // 保存时长
		{
			id: 1,
			sort_num: 1,
			name: "1",
			cn:{
				month:1,
				year :10,
			},
			other:{
				month:2,
				year :20,
			},
		}, {
			id: 3,
			sort_num: 2,
			name: "3",
			cn:{
				month:2,
				year :20,
			},
			other:{
				month:4,
				year :40,
			},
		},
		{
			id: 7,
			sort_num: 3,
			name: "7",
			cn:{
				month:3,
				year :30,
			},
			other:{
				month:6,
				year :60,
			},
		},
		{
			id: 15,
			sort_num: 4,
			name: "15",
			cn:{
				month:6,
				year :60,
			},
			other:{
				month:12,
				year :120,
			},
		},
		{
			id: 30,
			sort_num: 5,
			name: "30",
			cn:{
				month:15,
				year :150,
			},
			other:{
				month:30,
				year :300,
			},
		},
	]), // 保存时长
	stockmonth: new ParamClass([ // 库存时长
		{
			id: 1,
			sort_num: 1,
			name: "1",
		},{
			id: 3,
			sort_num: 2,
			name: "3",
		},{
			id: 6,
			sort_num: 3,
			name: "6",
		},{
			id: 12,
			sort_num: 4,
			name: "12",
		},
	]), // 库存时长
	payment: new ParamClass([ // 支付类型
		{
			id: 0,
			sort_num: 0,
			name: "free",
		},{
			id: 1,
			sort_num: 1,
			name: "alipay",
		},{
			id: 2,
			sort_num: 2,
			name: "wechart",
		},{
			id: 3,
			sort_num: 3,
			name: "paypal",
		},{
			id: 4,
			sort_num: 4,
			name: "comp_pay",
		},{
			id: 5,
			sort_num: 5,
			name: "cdk",
		},{
			id: 6,
			sort_num: 6,
			name: "payu",
		},{
			id: 7,
			sort_num: 7,
			name: "stripe",
		},{
			id: 8,
			sort_num: 8,
			name: "paytm",
		},{
			id: 9,
			sort_num: 9,
			name: "Razorpay",
		},
	]), // 支付类型
	roleState: new ParamClass([ // 角色状态
		{
			id: 1,
			sort_num: 1,
			name: "open",
		},{
			id: 2,
			sort_num: 2,
			name: "disable",
		},
	]), // 角色状态
}

export default Param