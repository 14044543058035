import { dataToSendData } from "@/common/interface_api/check_send.js";
export default function(api){
    api.__createBearerApi(["addGood", "MALL-新增商品"], "/v2/mall/admin/product/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["inr_price", "inr_fact_price", "usd_price", "usd_fact_price"
            , "comp_cn_price", "comp_other_price", "stripe_id", "rmb_price" , "rmb_fact_price"
            , "name", "img", "attr","promotion_code", "razorpay_id"],
            numKey : ["id", "state", "limit", "stock", "month", "only", "other_id", "type",
                "category_id","sort", "comp_id"],
        });
        if(data.rmb_price && !sendData.price){
            sendData.price = data.rmb_price;
        }
        if(data.rmb_fact_price && !sendData.fact_price){
            sendData.fact_price = data.rmb_fact_price;
        }
        return sendData
    });

    api.__createBearerApi(["editGood", "MALL-修改商品"], "/v2/mall/admin/product/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["inr_price", "inr_fact_price", "usd_price", "usd_fact_price"
            , "comp_cn_price", "comp_other_price", "stripe_id", "rmb_price" , "rmb_fact_price"
            , "name", "img", "attr","promotion_code", "razorpay_id"],
            numKey : ["id", "state", "limit", "stock", "month", "only", "other_id", "type",
                "category_id","sort", "comp_id"],
        });
        if(data.rmb_price && !sendData.price){
            sendData.price = data.rmb_price;
        }
        if(data.rmb_fact_price && !sendData.fact_price){
            sendData.fact_price = data.rmb_fact_price;
        }
        return sendData
    });

    api.__createBearerApi(["delGood", "MALL-删除商品"], "/v2/mall/admin/product/del", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["owner_id", "inr_price", "inr_fact_price", "usd_price", "usd_fact_price"
            , "comp_cn_price", "comp_other_price", "stripe_id", "rmb_price" , "rmb_fact_price"
            , "name", "img", "attr","promotion_code"],
            numKey : ["id", "state", "limit", "stock", "month", "only", "other_id", "type",
                "category_id","sort", "comp_id"],
        });
        if(sendData.rmb_price && !sendData.price){
            sendData.price = sendData.rmb_price;
        }
        if(sendData.rmb_fact_price && !sendData.fact_price){
            sendData.fact_price = sendData.rmb_fact_price;
        }
        return sendData
    });
    
    api.__createApi(["addGoodClass", "MALL-添加属性"], "/mall/category/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["name", "img","attr"],
            numKey : ["id", "parent_category_id"],
        });
        return sendData
    });
    
    api.__createApi(["editGoodClass", "MALL-修改属性"], "/mall/category/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["name", "img","attr"],
            numKey : ["id", "parent_category_id"],
        });
        return sendData
    });
    
    api.__createApi(["delGoodClass", "MALL-删除属性"], "/mall/category/delete", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["name", "img","attr"],
            numKey : ["id", "parent_category_id"],
        });
        return sendData
    });

    api.__createBearerApi(["setAppProduct", "MALL-商品关联设置"], "/v2/mall/admin/product/apprela/set" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_id",],
            numKey : ["link_type"],
            numArrayKey : ["ids"]
        });
        return sendData
    });

    api.__createBearerApi(["getAppProduct", "MALL-获取商品关联设置"], "/v2/mall/admin/product/apprela/list" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_id",],
            numKey : ["link_type", "page_no", "page_size"],
            numArrayKey : []
        });
        return sendData
    });

    api.__createApi(["getGoodClass", "MALL-获取商品信息"], "/mall/category/query" ,function(data){
        if(!data){
            data = {};
        }
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
        return sendData
    });

    api.__createBearerApi(["getGood", "MALL-获取商品列表"], "/v2/mall/admin/product/list" , function(data){
        var sendData = dataToSendData(data, {
            strKey : ["seller_id", "app_id", "pk"],
            numKey : ["category_id","comp_id", "page_index", "page_size", "link_type", "spec_type", "is_spec"],
        });
        sendData.page_no = sendData.page_index
        sendData.owner_id = sendData.seller_id
        sendData.link_type = !!data.link_type || data.link_type ===0 ?data.link_type :2 // APP ID :2 | PK : 1
        return sendData
    });
    api.__createBearerApi(["getBindGood", "MALL-获取绑定商品列表"], "/v2/mall/admin/product/pkrela/list" , function(data){
        var sendData = dataToSendData(data, {
            strKey : ["pk"],
            numKey : ["page_no", "page_size", "link_type"],
        });
        return sendData
    });
    api.__createBearerApi(["setGiveGood", "MALL-设定赠送商品列表"], "/v2/mall/admin/product/pkrela/set" , function(data){
        var sendData = dataToSendData(data, {
            strKey : ["pk"],
            numKey : ["link_type"],
            numArrayKey:["ids"],
        });
        return sendData
    });

    api.__createBearerApi(["getBuyList", "MALL-获取购买列表"], "/v2/mall/admin/order/list" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_id", "user_id", "order_id", "link_id", "pay_order_id", "platform_order_id"],
            numKey : ["comp_id", "page_no", "page_size", "order_state", "gmt_start", "gmt_end"],
        });
        sendData.app_ids = data.app_ids
        return sendData
    });

    // 查询订单数据
    api.__createApi(["getOrderStatistical", "MALL-获取订单统计数据"], "/mall/statis/order" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["start_date", "end_date"],
            numKey : ["type"],
        });
        sendData.app_id = data.app_id
        return sendData
    });
    api.__createApi(["getOrderaCompStatistical", "MALL-获取公司订单数据统计"], "/mall/statis/order/comp" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["start_date", "end_date"],
            numKey : ["type"],
        });
        sendData.comp_id = data.comp_id
        return sendData
    });
    api.__createApi(["getUserCloudInfo", "MALL-查询云存储订单信息"], "/mall/oss/order/query" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_id", "uid", "user_id"],
            numKey : ["channel", "state", "page_no", "page_size", "type", "bind"],
        });
        if(data.order_state){
            sendData.order_state = parseInt(data.order_state)
        }
        if(!data.state && data.order_state){
            sendData.state = data.order_state
        }
        sendData.app_ids = data.app_ids
        return sendData
    });
    api.__createApi(["getOtherPlatform", "MALL-获取第三方平台信息"], "/mall/otherplatform/product/query" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["type", "order_id"],
        });
        return sendData
    });

    api.__createApi(["getOtherPlatformInfo", "MALL-查询第三方平台"], "/mall/otherplatform/query", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["type", "page_no", "page_size"],
        });
        return sendData
    })
    api.__createApi(["addOtherPlatformInfo", "MALL-添加第三方平台"], "/mall/otherplatform/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_key" , "app_secret", "comp_id","name"],
            numKey : ["type"],
        });
        return sendData
    })
    api.__createApi(["editOtherPlatformInfo", "MALL-修改第三方平台"], "/mall/otherplatform/modify", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_key" , "app_secret","comp_id","name"],
            numKey : ["type", "id"],
        });
        return sendData
    })
    api.__createApi(["delOtherPlatformInfo", "MALL-删除第三方平台"], "/mall/otherplatform/delete", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
        return sendData
    })
    api.__createApi(["bindAppOtherPlatform", "MALL-设置APP第三方平台绑定"], "/mall/otherplatform/appbind/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_id"],
            numKey : [],
            numArrayKey : ["platform_id"]
        });
        return sendData
    })
    api.__createApi(["getAppOtherPlatformBind", "MALL-查询APP第三方平台绑定"], "/mall/otherplatform/appbind/get", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_id"],
            numKey : [],
        });
        return sendData
    })
    api.__createBearerApi(["getIotOrder", "MALL-IOT订单查询"], "/v2/mall/uid/order/list", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["sys", "uid","user_id","app_id","start_time","end_time"],
            numKey : ["state", "page_size","page_no"],
        });
        sendData.app_ids = data.app_ids
        return sendData
    })

    api.__createApi(["unbindCS" ,"MALL-商品订单绑定"],"/mall/order/bind/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : [ "category_id", "order_id", "user_id", "uid"],
            numKey : ["type","channel"],
        });
        return sendData
    })
    api.__createApi(["buildXls", "MALL-导出订单"], "/mall/order/export/add" ,function(data){
        return dataToSendData(data, {
            strKey : ["link_id"],
            numKey : [ "order_state", "gmt_start", "gmt_end", "link_type", "comp_id", "pay_type"],
        });
    })
    api.__createApiByAppId(["pushPayList", "MALL-添加订单"], "/mall/order/add" ,function(data){
        return dataToSendData(data, {
            //"app_id",
            strKey : ["user_id", "uid", "spec_id", "order_param"],
            numKey : ["buy_num", "pay_type", "fee_type", "channel"],
        });
    })
    api.__createApiByAppId(["getOrderInfo", "MALL-查询订单"], "/mall/order/query" ,function(data){
        return dataToSendData(data, {
            strKey : ["user_id", "uid", "pay_id"],
            numKey : ["channel", "state", "page_no", "page_size", "order_state"],
        });
    })
    // 查询激活码
    api.__createApiByAppId(["getActCode", "MALL-查询激活码"], "/mall/cdk/get" ,function(data){
        return dataToSendData(data, {
            strKey : ["order_id","cdk"],
            numKey : ["page_no", "page_size", "state", "comp_id"],
        });
    })
    // 
    api.__createApiByAppId(["getActCodeBatch", "MALL-查询激活码批次"], "/mall/cdk/order/get" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["page_no", "page_size", "sycn_state", "order_state"],
        });
        return sendData
    })
    // 添加 激活码批量
    api.__createApiByAppId(["addActCodeBatch", "MALL-批量添加激活码"], "/mall/cdk/order/add",function(data){
        return dataToSendData(data, {
            strKey : ["spec_id"],
            numKey : ["comp_id", "public", "use_zone", "gmt_expire", "number"],
        });
    })
    // 修改 激活码批量
    api.__createApiByAppId(["editActCodeBatch", "MALL-修改激活码批次"], "/mall/cdk/order/set",function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : ["id", "comp_id", "public", "use_zone"],
        });
    })
    api.__createApi(["goodPull", "MALL-获取其他商品Pull"], "/mall/other/goods/pull",function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
    })
    api.__createApi(["getIccIdList", "MALL-获取ICC ID"], "/mall/iccid/get",function(data){
        return dataToSendData(data, {
            strKey : ["iccid"],
            numKey : ["page_no", "page_size", "type", "comp_id"],
        });
    })

    api.__createApi(["importIccId", "MALL-导入ICC ID"], "/mall/iccid/import/add",function(data){
        return dataToSendData(data, {
            strKey : ["iccid","link_id"],
            numKey : ["link_type", "network_type", "brand_id","type", "comp_id"],
        });
    })
    api.__createApi(["getImportIccInfo", "MALL-获取导入ICC ID文件记录"], "/mall/iccid/import/get",function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : ["gmt_start", "gmt_end", "page_size","page_no", "state"],
        });
    })
    api.__createApiByAppId(["actCode", "MALL-使用CDK"], "/mall/cdk/use" ,function(data){
        return dataToSendData(data, {
            strKey : ["cdk", "uid", "user_id","file_url"],
            numKey : ["channel","start_type"],
        });
    });
    api.__createApiByAppId(["addImportOrder", "MALL-添加导入订单文件"], "/mall/order/import/add" ,function(data){
        return dataToSendData(data, {
            strKey : ["file_path", "spec_id"],
            numKey : ["comp_id"],
        });
    });
    api.__createApiByAppId(["getImportOrder", "MALL-查询导入订单文件信息"], "/mall/order/import/get" ,function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : ["state", "gmt_start", "gmt_end", "page_size", "page_no"],
        });
    });

    api.__createApi(["getLiveOrder", "MALL-查询Live订单"], "/mall/live/order/query" ,function(data){
        return dataToSendData(data, {
            strKey : ["user_id", "app_id", "uid"],
            numKey : ["state", "bind", "page_size", "page_no"],
        });
    });
    api.__createApi(["getXlsData", "MALL-查询导出的订单文件"], "/mall/order/export/get" ,function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : [ "page_no", "page_size", "state", "gmt_start", "gmt_end"],
        });
    })
    api.__createApiByAppId(["getCdkUse", "MALL-查询批量导入的CDK"], "/mall/cdk/use/import/get" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["page_no","page_size","id"],
        });
        return sendData;
    });
    api.__createBearerApi(["getBalance", "PAY-查询余额"], "/v2/mall/comp/money/get" ,function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : ["comp_id"],
        });
    })
    api.__createBearerApi(["setBalance", "PAY-给其他账号(公司)充值"], "/v2/mall/comp/money/set" ,function(data){
        return dataToSendData(data, {
            strKey : ["to_user_id", "money"],
            numKey : ["to_comp_id", "money_type", "type"],
        });
    })
    // 查询用户流水
    api.__createBearerApi(["getUserFlow", "PAY-查询用户流水"], "/v2/mall/comp/moneyflow/list" ,function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : ["type", "gmt_start", "gmt_end", "page_no", "page_size"],
        });
    })

}